// General
import NFCCLogo from "./images/nfcc-logo.png";
import NFCCMenuLogo from "./images/nfcc_menu_logo.png";
import AgencyHome from "./images/agency_home.svg";
export const LOGO = NFCCLogo;
export const MENU_LOGO = NFCCMenuLogo;
export const HEADERS_HEIGHT = "64px";
export const LOGO_HEIGHT = "46px";
export const ACCOUNT_SID = process.env.REACT_APP_ACCOUNT_SID || "";
export const TOKEN = process.env.REACT_APP_TOKEN || "";

// Color Section
export const FORM_BACKGROUND = "rgb(255, 255, 255)";
export const SUCCESS = "#64CCC9";
export const UNAVAILABLE = "#003939";
export const RED = "#BD0060";
export const WEAKEST = "#e8f3f1";

export const Role = {
  admin: "nfcc_admin",
  client: "nfcc_agency",
};

export const timezones = [
  { name: "EST", tag: "America/New_York", isDaylightSavings: false }, // Eastern Standard Time
  { name: "EDT", tag: "America/New_York", isDaylightSavings: true }, // Eastern Daylight Time

  { name: "CST", tag: "America/Chicago", isDaylightSavings: false }, // Central Standard Time
  { name: "CDT", tag: "America/Chicago", isDaylightSavings: true }, // Central Daylight Time

  { name: "MST", tag: "America/Denver", isDaylightSavings: false }, // Mountain Standard Time
  { name: "MDT", tag: "America/Denver", isDaylightSavings: true }, // Mountain Daylight Time

  { name: "PST", tag: "America/Los_Angeles", isDaylightSavings: false }, // Pacific Standard Time
  { name: "PDT", tag: "America/Los_Angeles", isDaylightSavings: true }, // Pacific Daylight Time

  { name: "HST", tag: "Pacific/Honolulu", isDaylightSavings: false }, // Hawaii Standard Time (no DST)
  { name: "HSDT", tag: "Pacific/Honolulu", isDaylightSavings: true }, // Hawaii Daylight Time

  { name: "AKST", tag: "America/Anchorage", isDaylightSavings: false }, // Alaska Standard Time
  { name: "AKDT", tag: "America/Anchorage", isDaylightSavings: true }, // Alaska Daylight Time

  { name: "AST", tag: "America/Halifax", isDaylightSavings: false }, // Atlantic Standard Time
  { name: "ADT", tag: "America/Halifax", isDaylightSavings: true }, // Atlantic Daylight Time

  { name: "NST", tag: "America/St_Johns", isDaylightSavings: false }, // Newfoundland Standard Time
  { name: "NSDT", tag: "America/St_Johns", isDaylightSavings: true }, // Newfoundland Daylight Time

  { name: "PMST", tag: "America/Miquelon", isDaylightSavings: false }, // Pierre & Miquelon Standard Time
  { name: "PMDT", tag: "America/Miquelon", isDaylightSavings: true }, // Pierre & Miquelon Daylight Time

  { name: "WGST", tag: "America/Godthab", isDaylightSavings: true }, // West Greenland Summer Time
  { name: "WGT", tag: "America/Godthab", isDaylightSavings: false }, // West Greenland Time

  { name: "EGST", tag: "Europe/London", isDaylightSavings: true }, // Eastern Greenland Summer Time
  { name: "EGT", tag: "Europe/London", isDaylightSavings: false }, // Eastern Greenland Time
  { name: "GMT", tag: "Europe/London", isDaylightSavings: false }, // Greenwich Mean Time
];

export const standardTimezones = timezones.filter(
  (tz) => !tz.isDaylightSavings && !tz.tag.includes("Europe")
);

export const allStates = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
  { name: "Alberta", abbreviation: "AB" },
  { name: "British Columbia", abbreviation: "BC" },
  { name: "Manitoba", abbreviation: "MB" },
  { name: "New Brunswick", abbreviation: "NB" },
  { name: "Newfoundland and Labrador", abbreviation: "NL" },
  { name: "Nova Scotia", abbreviation: "NS" },
  { name: "Ontario", abbreviation: "ON" },
  { name: "Prince Edward Island", abbreviation: "PE" },
  { name: "Quebec", abbreviation: "QC" },
  { name: "Saskatchewan", abbreviation: "SK" },
  { name: "Northwest Territories", abbreviation: "NT" },
  { name: "Nunavut", abbreviation: "NU" },
  { name: "Yukon", abbreviation: "YT" },
];

export const statesList = allStates.map((s) => {
  return s.name;
});
